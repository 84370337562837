import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TermsContentTemplate from "../templates/legal/terms-content-template"
import {Helmet} from 'react-helmet'


const Terms = () => (
  <>
    <Layout>
      <Helmet>
        <body className="inner-page no-hero legal-page" data-active-page="page-terms" />
      </Helmet>
      <SEO title="Terms" />
      <section className="content-container">
        <TermsContentTemplate />
      </section>
    </Layout>
  </>
)

export default Terms
